<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate } from '@tolgee/svelte';
	import { createEventDispatcher } from 'svelte';

	export let label: string | undefined = undefined;
	export let helperText: string | undefined = undefined;
	export let className: string | undefined = undefined;
	export let showClearButton: boolean | undefined = undefined;
	export let showChevron: boolean | undefined = undefined;
	export let fullColorIcon: boolean | undefined = undefined;
	export let showSearch: boolean | 'nosubmit' | undefined = undefined;
	export let customInputClass: string | undefined = undefined;
	export let placeholder: string | undefined = undefined;
	export let errors;
	export let value;
	export let name: string | undefined = undefined;
	export let focus: boolean | undefined = undefined;
	export let type: string = 'text';
	export let required: boolean = false;

	const TOLGEE_MAP = {
		'email.required': 'textInput.email.requiredMessage',
		'password.required': 'textInput.password.requiredMessage',
		'email.not_an_email': 'validators.error.email',
		'password.min': 'validators.error.password',
		'passwordConfirm.match_field': 'changePassword.form.error.doNotMatch'
	};

	const { t } = getTranslate();
	const dispatch = createEventDispatcher();

	$: error = errors.find((s) => s.includes(name));
	$: isDateField = type?.includes('date');
	$: hasValue = !!value || isDateField;
	$: isNewsletterInput = name === 'newsLetterEmail';
	$: inputStylingError = `text-special-danger placeholder-special-danger border-special-danger focus:ring-special-danger focus:border-special-danger ${
		isNewsletterInput ? 'newsletter-input-footer' : 'pt-5 pb-1'
	}`;
	$: inputStyling = `${
		isNewsletterInput && (focus || hasValue)
			? 'border-opacity-50 focus:border-white'
			: isNewsletterInput
				? 'border-opacity-100 focus:border-accent-primary'
				: focus || hasValue
					? '!border-accent-primary pt-5 pb-1'
					: 'border-accent-primary/35 !bg-opacity-5 py-3'
	} ${
		isNewsletterInput ? 'border-light-secondary newsletter-input-footer' : ''
	} ring-0 bg-opacity-10`;

	const onFocus = () => {
		focus = true;
	};
	const onBlur = () => {
		focus = false;
	};

	export { className as class };
</script>

<div class={classes('flex flex-col space-y-2', className)}>
	<div class="relative overflow-hidden rounded bg-accent-primary_005">
		<label
			for={name}
			class={classes(
				'absolute px-3 py-1 transition-all !normal-case',
				error ? 'text-special-danger' : isNewsletterInput ? 'text-white' : 'text-accent-primary',
				focus || hasValue
					? 'text-opacity-100 top-0 translate-y-0 text-p-12'
					: `text-opacity-35 top-1/2 -translate-y-1/2 ${
							isNewsletterInput ? 'text-light-primary' : ''
						}`,
				showSearch && 'pl-8',
				isNewsletterInput ? 'text-p-12' : 'text-p-16'
			)}
		>
			{label}
		</label>

		<input
			class={classes(
				'autofill-input-global bg-gray-100 border-b-[0.15rem] border-t-0 border-x-0 outline-none text-h-16 text-accent-primary !normal-case placeholder-dark-tertiary w-full',
				error ? inputStylingError : inputStyling,
				showSearch ? 'pl-8' : 'pl-3',
				customInputClass
			)}
			style={isNewsletterInput
				? focus || hasValue
					? `background-color: rgba(255, 255, 255, .1);
                  color: white;
                  height: 50px;
                  padding-top: 20px;
                  transition: height 0.3s linear;`
					: `background-color: rgba(255, 255, 255, .1);
                  color: white;
                  transition: height 0.3s linear;`
				: ''}
			{type}
			id={name}
			{placeholder}
			{value}
			on:input={(e) => (value = e.target?.value)}
			on:focus={onFocus}
			on:blur={onBlur}
			aria-invalid={!!error}
			aria-describedby={`${name}-error`}
			{required}
		/>

		<div class="absolute left-2 inset-y-0 grid place-items-center">
			{#if showSearch}
				{#if typeof showSearch === 'string' && showSearch === 'nosubmit'}
					<div
						class={classes(
							'h-5 transition duration-500 i-sprenger-search',
							focus || hasValue || fullColorIcon ? 'text-accent-primary' : 'text-accent-primary/50'
						)}
					></div>
				{:else}
					<button
						class={classes(
							'h-5 transition duration-500 i-sprenger-search',
							focus || hasValue || fullColorIcon ? 'text-accent-primary' : 'text-accent-primary/50'
						)}
						type="submit"
						aria-label="submit"
					>
					</button>
				{/if}
			{/if}
		</div>

		<div
			class="absolute inset-y-0 right-0 flex items-center justify-center pl-1 pr-2 m-1 space-x-1 pointer-events-none"
		>
			{#if showClearButton}
				<button
					type="button"
					on:click={() => {
						value = '';
						dispatch('clear');
					}}
					class="pointer-events-auto w-5 h-5 text-accent-primary i-sprenger-x"
				>
				</button>
			{/if}

			{#if error}
				<div class="w-5 h-5 text-special-danger i-sprenger-alert-circle" aria-hidden="true" />
			{/if}
		</div>
		<div class="flex flex-col justify-center absolute right-0 top-0 lg:-mr-3 h-full">
			{#if showChevron && !error}
				<button type="submit" aria-label="submit">
					<div
						class={`h-5 w-12 cursor-pointer ${
							isNewsletterInput ? 'text-white' : 'text-accent-primary pr-2'
						} pr-1 lg:pr-3 i-sprenger-chevron-right`}
					/>
				</button>
			{/if}
		</div>
	</div>

	{#if error}
		<p
			class={classes(
				isNewsletterInput ? 'text-special-danger text-p-12 !mt-0' : 'text-special-danger text-p-12'
			)}
			id={`${name}-error`}
		>
			{$t(TOLGEE_MAP[error] || error)}
		</p>
	{/if}
	{#if helperText}
		<p class="text-p-12 text-dark-tertiary">{helperText}</p>
	{/if}
</div>
